/* .screen_header {
    background-color: #24263a;
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 3.1rem 3rem;
  }
  .screen_heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--white-2);
  } */

  .post-map {
    height       : 41.8rem;
    max-width    : 100%;
    /* width        : 80rem; */
    border       : 2px solid var(--gray-2);
    border-radius: 1.6rem;
    margin-bottom: 2.4rem;
}
