.verify_email_container{
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 24px;
  width: 20vw;
  margin: 0 auto;
  }
  
  .text-logo-container {
    background-color: #fff;
    height: 120px;
    width: 121px;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 10px;
  }
  
  .text-logo {
    text-align: center;
    font-size: 24px;
    color: black;
  }

  .logo-coloring-text{
    color:  #27107A
  
  }
  
  .text_heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: rgb(0,0,0,0.7)
  }

  .instruction_container{
 color: rgb(0,0,0,0.7);
 padding-bottom: 24px;
  }



.loading_image{
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.next_btn_container{
    width: 100%;
    display: grid;
    place-items: end;
}

.next_btn{  
    margin-top: 20px;
    margin-right: 20px;
    background-color:var(--gray-3);
    padding: 16px 24px;
    border-radius: 8px;
    color: rgb(0,0,0);
    font-weight: 500;
}
  