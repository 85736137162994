/* fonts import */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* root variables */
:root {
  --primary-color: #322a5d;
  --black: #000;
}

/* body, html and root imports */
*,
body,
html {
  font-family: "Inter", sans-serif;
}

body {
  font-size: 1.6rem;
  background-color: var(--black);
  color: var(--gray-3);
}

a {
  color: var(--primary-color);
}

.grid_container {
  display: grid;
}

.flex_container {
  display: flex;
}

/* --------------------------------------------------------------- */

/* main {
  height : 100vh;
  width  : 100vw;
  display: grid;
  grid-template-columns: auto 1fr;
} */

.main {
  width: 100vw;
  height: 100vh;
  display: grid;
  background-color: #f2f2f2;
  overflow: hidden;
}

.main.seller {
  grid-template-columns: auto 1fr;
}

.main.auth {
  /* place-items: center; */
  grid-template-columns: 1fr;
}

.w_100 {
  width: 100%;
}

.modal_group_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: vw; */
  height: 100vh;
  padding: 0 2rem 0 2rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.screen-container.auth {
  padding: 0px;
}

.register.screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 0 2rem 0 4rem;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.register.screen-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.register.screen-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* btn group */
.btn_group {
  display: grid;
  gap: 2.4rem;
  justify-content: flex-end;
}
.btn_group.two {
  grid-template-columns: auto auto;
}
.file_upload_input[type="file"] {
  display: none;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}
