.selected_size_area {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 10px;
}
.selected_size_area .selected_size {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.selected_size {
  min-width: 100px;
  font-size: 12px;
  background-color: rgb(50, 42, 93);
  color: white;
  padding: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  -ms-border-radius: 200px;
  -o-border-radius: 200px;
}

.select_size_input_group {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.select_size_input_group > .size_input,
.price_input {
  width: 100%;
  text-transform: capitalize;
}

.price_input input {
  padding: 5px;
}
.price_input::before {
  content: "$";
}

.add_size_button {
  background: #322a5d;
  color: white;
  border: none;
  min-height: 4rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.add_size_button.disabled {
  background: #65646d;
}
