#dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* #dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.dashboard {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-row-gap: 40px;
    overflow-y: scroll;
  }
  
  .card {
    background-color: var(--gray-8); /* Set background color */
    border: 1px solid var(--primary-color); /* Add border for styling */
    border-radius: 8px; /* Add border-radius for rounded corners */
    padding: 20px;
    min-height: 150px; /* Add padding for spacing inside the card */
  }
  
  .card h3 {
    font-size: 30px;
    padding-top: 40px;
  }
  .card p {
    font-size: 20px;
  }
  
  .dashboard_crud_holder {
    width: 100%;
    height: 100%;
    padding: 4rem 0 2rem;
   overflow: hidden;
   display: grid;
   grid-template-rows: 30% 70%;
   gap: 20px;
  }
  

  /* Hide scrollbar for Chrome, Safari and Opera */
  .dashboard::-webkit-scrollbar {
    display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .dashboard {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }