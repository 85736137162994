.data_table_wrapper {
    height       : calc(100vh - 20vh);
    background   : white;
    
    border-radius: 0.4rem;
    /* padding      : 1rem; */
    overflow-x   : hidden;
    overflow-y   : auto;
    border-width: 1px;
    border-color: #e8e8e8;
}

table {
    width         : 100%;
    border-spacing: 0;
    position      : relative;
}

thead,
tbody,
tfoot,
th,
td {
    font-size   : inherit;
    border-width: 0;
    border-style: solid;
}

.dashboard_table thead {
    position: sticky;
    top     : 0;
    background: var(--gray-8);
}

.dashboard_table th:first-child,
.dashboard_table td:first-child {
    text-align: left;
}

.dashboard_table th:last-child,
.dashboard_table td:last-child {
    text-align: right;
}

/* .dashboard_table th,
.dashboard_table td {
    max-width: 25rem;
} */

.dashboard_table td {
    font-size  : 2rem;
    line-height: 2.5rem;
    color      : var(--dark-gray);
    text-align : center;
    /* padding    : 2rem 1rem; */
    padding: 1rem 2rem;
}
.dashboard_table tr {
    transition: all 100ms;
}
.dashboard_table tr:hover {
    background-color: #322A5D30;
    /* padding    : 2rem 1rem; */
}

.dashboard_table td>img {
    width    : 100%;
    max-width: 7rem;
}