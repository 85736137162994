.delete_btn:hover {
  background-color: var(--bright-red) !important;
  color: white
}

.delete_btn>svg>* {
  stroke: var(--bright-red) !important;
}

.delete_btn:hover>svg>* {
  stroke: var(--dark-red) !important;
}