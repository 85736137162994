.select_field {
  width: 100%;
  color: black;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.select_field option {
  background-color: rgb(255, 255, 255);
  height: 1rem;
  outline: none;
  color: black;
}
