.sidebar_nav .item:not(:last-child) {
  margin-bottom: 0.86rem;
  width: 100%;
}

.sidebar_nav a {
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--white-2);
  /* border-radius: 0.8rem; */
  padding: 0.8rem 3rem;
  border: 1px solid #fffffff8
}

.item a.active {
  background-color: var(--primary-color);
  color: white;
  /* border-radius: 66.667px 0px 0px 66.667px; */
}

.sidebar_nav a svg {
  width: 2.4rem;
  height: 2.4rem;
}

.sidebar_nav a > span {
  padding-left: 2.4rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: RGB(19, 19, 19 ,0.7)
}

.sidebar_nav a.active svg {
  color: var(--white);
}

.sidebar_nav a.active span {
  color: var(--white);
}

.item a:hover {
  background-color: var(--primary-color);
  color: var(--white);
  width: 100%;
}
.item a:hover span {
  color: var(--white);

}
.item a >span:hover {
  color: var(--white);
}
