.form_wrapper .upload_file_wrapper {
  display: block;
  margin-bottom: 2.4rem;
}
.screen-container {
  background-color: var(--gray-8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 0 2rem 0 4rem;
  overflow-x: hidden;
  overflow-y: auto;
}
