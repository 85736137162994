.action_button_cell button,
.action_button_cell button>svg>*,
.action_button_cell button>svg>g>path:nth-child(2) {
  transition: all 0.5s ease;
}

.action_button_cell button {
  background-color: var(--gray-5);
  border-radius   : 50%;
  border          : none;
  width           : 4.4rem;
  height          : 4.4rem;
  display         : inline-flex;
  align-items     : center;
  justify-content : center;
}

.action_button_cell button:hover {
  background-color: #bcb0ff;
}

.action_button_cell button:not(:last-child) {
  margin-right: 1.2rem;
}

.action_button_cell button>svg {
  width : 2.3rem;
  height: 2.3rem;
}

/* react fi icons */
.action_button_cell button>svg>* {
  stroke: var(--black);
}

.action_button_cell button:hover>svg>* {
  stroke: var(--secondary-color);
}


/* react ri icons */
.action_button_cell button>svg>g>path:nth-child(2) {
  fill: var(--black);
}

.action_button_cell button:hover>svg>g>path:nth-child(2) {
  fill: var(--secondary-color);
}