.file_upload_input[type="file"] {
    display: none;
}
.banner_preview_image {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: 100%;
    webkit-transition: all linear 300ms;
    transition: all linear 300ms;
    cursor: pointer;
    border-radius: 5px;
    background: transparent;
}
.banner_preview_image:hover{
    background: var(--gray);
}
.banner_preview_image img {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    webkit-transition: all linear 300ms;
    transition: all linear 300ms;
    mix-blend-mode: normal;
}
.banner_preview_image img:hover{
    mix-blend-mode: multiply;

}