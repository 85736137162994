.sidebar_nav {
  height: 80vh;
  padding-top: 30px;
  display: grid;
  grid-template-rows: 70vh 10vh;
}


.sidebar_nav > *:nth-child(2){
  position: relative;
  padding-left: 2rem;
  /* padding-top: 5rem; */
  /* margin-left: 2rem; */
}

.d_items {
  margin-left: 3rem;
  padding-bottom: 1.5rem;
}


.sidebar_nav > *:nth-child(1) {
  /* height: 53vh; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* padding-left: 2rem; */
}

.sidebar_nav > *:nth-child(1)::-webkit-scrollbar {
  display: none;
}

.sidebar_nav button {
  /* color: rgba(0, 0, 0, 0.7); */
  
}
.item.log_out:hover {
  background: var(--primary-color);
  color: var(--white)
}

.item.log_out:hover {
    color: var(--primary-color);
}

/* .sidebar_nav button.active {
  background-color: var(--primary-color);
  color: var(--primary-color);
}

.sidebar_nav button svg {
  width: 2.4rem;
  height: 2.4rem;
}

.sidebar_nav button > span {
  padding-left: 2.4rem;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sidebar_nav button:hover {
  color:var(--white);
} */
