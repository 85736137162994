.screen_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-8);
  border-radius: 1rem;
  padding: 2.2rem 4.4rem;
  /* margin: 4.8rem 0 2.4rem; */
  margin-bottom: 2.4rem;
  box-shadow: 0px 0px 3px 0px;
}
.screen_heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--white-2);
}

.field_label {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0;
  line-height: normal;
  color: var(--black);
}
